import "./scss/index.scss";
import useResponsive from "./utils/useResponsive";


import Footer from "./components/footer/Footer";
import Contact from "./sections/contact/Contact";
import LandingPage from "./sections/landing/Landing";
import Projects from "./sections/projects/Projects";
import Services from "./sections/services/Services";

import Navbar from "./components/navbar/Navbar";
import NavbarMobile from "./components/navbar/NavbarMobile";

function App() {
  const { bigtablet } = useResponsive();

  return (
    <div className="App">
      {!bigtablet ? (<Navbar />) : (<NavbarMobile />)}
      <LandingPage />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
