import React, { useEffect, useState } from "react";
import "./Projects.scss";
import useResponsive from "../../utils/useResponsive";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../db/firebase";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Card from "../../components/projectCards/ProjectCards";

const Projects = () => {
  const projectsCollection = collection(db, "projects");
  const [projects, setProjects] = useState([]);
  const { bigtablet } = useResponsive();

  useEffect(() => {
    const getProjects = async () => {
      const data = await getDocs(projectsCollection);
      setProjects(
        data.docs.map((project) => ({ ...project.data(), id: project.id }))
      );
    };
    getProjects();
  }, []);

  useEffect(() => {
    if (projects.length === 0) return;

    if (projects.length > 0) {
      let projectAnchors = document.getElementsByClassName("projectAnchors");

      for (let i = 0; i < projectAnchors.length; i++) {
        if (projectAnchors[i].childNodes.length === 1) {
          projectAnchors[i].classList.add("one");
        } else {
          projectAnchors[i].classList.add("two");
        }
      }
    }
  }, [projects]);

  return (
    <div id="projects" className="projects">
      <div className="sectionTitle">
        <h2>Projects</h2>
        {!bigtablet ? <h3>Hover the cards</h3> : <h3>Click the cards</h3>}
      </div>
      <Container maxWidth="lg">
        <Grid
          container
          rowSpacing={5}
          columnSpacing={5}
          className="projectsGrid"
        >
          {projects.sort((a, b) => b.year - a.year) &&
            projects.map((project) => (
              <Card key={project.id} project={project} />
            ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Projects;
