import { useState } from "react";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

import { useMediaQuery } from "react-responsive";

function useResponsive() {
    const [isClient, setIsClient] = useState(false);

    const bigtablet = useMediaQuery({query: "(max-width: 1199px)" })
    const tabletBreakpoint = useMediaQuery({ query: "(max-width: 991px)" });
    const md = useMediaQuery({query: "(max-width: 900px)" });

    useIsomorphicLayoutEffect(() => {
        if (typeof window !== "undefined") setIsClient(true);
    }, []);

    return {

        bigtablet: isClient && bigtablet,
        tabletBreakpoint: isClient && tabletBreakpoint,
        md: isClient && md
    };
}

export default useResponsive;