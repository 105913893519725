import React, { useEffect, useState } from "react";
import "./Services.scss";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../db/firebase";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Card from "../../components/servicesCards/ServicesCard";

const Services = () => {
  const servicesCollection = collection(db, "services");
  const [services, setServices] = useState([]);

  useEffect(() => {
    const getServices = async () => {
      const data = await getDocs(servicesCollection);
      setServices(
        data.docs.map((service) => ({ ...service.data(), id: service.id }))
      );
    };
    getServices();
  }, []);

  return (
    <div id="services" className="services">
      <div className="sectionTitle">
        <h2>Services</h2>
      </div>
      <Container maxWidth="lg">
        <Grid
          container
          rowSpacing={5}
          columnSpacing={5}
          className="servicesGrid"
        >
          {services.sort((a, b) => a.order - b.order).map((service) => (
            <Card
              key={service.id}
              icon={service.icon}
              title={service.name}
              description={service.description}
            />
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Services;
