import React from "react";

import "./ServicesCard.scss";

import { ReactComponent as AnalyticsIcon } from "../../assets/img/analytics.svg";
import { ReactComponent as DesignIcon } from "../../assets/img/design.svg";
import { ReactComponent as WebIcon } from "../../assets/img/pc.svg";

import Grid from "@mui/material/Grid";

const ServicesCard = ({ icon, title, description }) => {
  const renderIcons = (icon) => {
    switch (icon) {
      case "web":
        return <WebIcon />;
      case "analytics":
        return <AnalyticsIcon />;
      case "design":
        return <DesignIcon />;
      default:
        break
    }
  };

  return (
    <Grid item lg={4} md={6} sm={12} xs={12} className="gridItem">
      <div className="serviceCard">
        <div className="cardContent">
          <div className="icon">{renderIcons(icon)}</div>
          <h3>{title}</h3>
          <p>{description}</p>
          <a className="anchor" href="#contact">
            Contact Me
          </a>
        </div>
      </div>
    </Grid>
  );
};

export default ServicesCard;
