import React from "react";

import "./ProjectCards.scss";

import Grid from "@mui/material/Grid";

const ProjectCards = ({ project }) => {
  return (
    <Grid item lg={4} md={6} sm={12} xs={12} className="gridItem">
      <div className="projectCard">
        <div className="cardContent">
          <div className="cardHeader">
            <p className="projectName">{project.name}</p>
            <p className="projectYear">{project.year}</p>
          </div>
          <div className="cardData">
            <div className="cardBody">{project.description}</div>
            <div className="projectAnchors">
              {project.frontend && (
                <a className="anchor" href={project.frontend} target="_blank" rel="noreferrer">
                  Frontend
                </a>
              )}
              {project.backend && (
                <a className="anchor" href={project.backend} target="_blank" rel="noreferrer">
                  Backend
                </a>
              )}
            </div>
          </div>


        </div>
      </div>
    </Grid>
  );
};

export default ProjectCards;
