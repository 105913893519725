// eslint-disable-next-line no-restricted-imports
import { useEffect, useLayoutEffect } from "react";

/*
 * React currently throws a warning when using useLayoutEffect on the server.
 * To get around it, we can conditionally useEffect on the server and
 * useLayoutEffect in the browser.
 */

export const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;
