import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

import { ReactComponent as GitHubIcon } from "../../assets/icons/github.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedIcon } from "../../assets/icons/linkedin.svg";

import "./NavbarMobile.scss";

import { getAnalytics, logEvent } from "@firebase/analytics";

const NavbarMobile = () => {
  const [isOverflow, setIsOverflow] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [navbar, setNavbar] = useState();

  useEffect(() => {
    setNavbar(document.querySelector(".burguerMenu"));
  }, []);

  window.onscroll = () => {
    if (window.scrollY >= 80) {
      navbar.classList.add("scroll");
    } else {
      navbar.classList.remove("scroll");
    }
  };

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isOverflow ? "auto" : "hidden";
    }
  }, [isOverflow]);

  const handleCheck = () => {
    setIsOverflow(!isOverflow);
    setIsChecked(!isChecked);
  };

  const handleEvent = (event, prop) => {
    const analytics = getAnalytics();
    logEvent(analytics, "click_" + prop);
  };

  return (
    <div className="mobileNavWrapper">
      <input
        type="checkbox"
        id="checkBurguer"
        className="checkBurguer"
        checked={isChecked}
      />

      <label
        className="burguerMenu"
        onClick={() => {
          handleCheck();
        }}
      >
        <div className="one"></div>
        <div className="two"></div>
        <div className="three"></div>
      </label>

      <div className="navbarMobile">
        <div className="content">
          <ul className={"nav-menu active"}>
            <li className="nav-item">
              <Link
                to="landing"
                spy={true}
                smooth={true}
                offset={-100}
                duration={100}
                onClick={handleCheck}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={100}
                onClick={handleCheck}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="services"
                spy={true}
                smooth={true}
                offset={50}
                duration={100}
                onClick={handleCheck}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="projects"
                spy={true}
                smooth={true}
                offset={50}
                duration={100}
                onClick={handleCheck}
              >
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={100}
                duration={100}
                onClick={handleCheck}
              >
                Contact
              </Link>
            </li>
          </ul>
          <div className="socialMediaIcons">
            <a
              onClick={(e) => handleEvent(e, "linkedin")}
              href="https://www.linkedin.com/in/lu%C3%ADs-fernandes-929712223/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIcon className="icon"></LinkedIcon>
            </a>
            <a
              onClick={(e) => handleEvent(e, "gitHub")}
              href="https://github.com/lfernandes00"
              target="_blank"
              rel="noreferrer"
            >
              <GitHubIcon className="icon"></GitHubIcon>
            </a>

            <a
              onClick={(e) => handleEvent(e, "instagram")}
              href="https://www.instagram.com/luis_fernandes00/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon className="icon"></InstagramIcon>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
