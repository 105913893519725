import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footerWrapper">
      <p className="footer">All Rights Reserverd © 2022, Luís Fernandes</p>
    </div>
  );
};

export default Footer;