import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { ReactComponent as GitHubIcon } from "../../assets/icons/github.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedIcon } from "../../assets/icons/linkedin.svg";

import "./Navbar.scss";

import { getAnalytics, logEvent } from "@firebase/analytics";

const Navbar = () => {
  const [navbar, setNavbar] = useState();

  useEffect(() => {
    setNavbar(document.querySelector(".navigationContent"));
  }, []);

  window.onscroll = () => {
    if (window.scrollY >= 80) {
      navbar.classList.add("scroll");
    } else {
      navbar.classList.remove("scroll");
    }
  };

  const handleEvent = (event, prop) => {
    const analytics = getAnalytics();
    logEvent(analytics, "click_" + prop);
  };

  return (
    <div className="navigation">
      <div className="navigationContent">
        <div className="socialMedia">
          <a
            onClick={(e) => handleEvent(e, "linkedin")}
            href="https://www.linkedin.com/in/lu%C3%ADs-fernandes-929712223/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIcon className="iconDesktop" />
          </a>
          <a
            onClick={(e) => handleEvent(e, "gitHub")}
            href="https://github.com/lfernandes00"
            target="_blank"
            rel="noreferrer"
          >
            <GitHubIcon className="iconDesktop" />
          </a>
          <a
            onClick={(e) => handleEvent(e, "instagram")}
            href="https://www.instagram.com/luis_fernandes00/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon className="iconDesktop" />
          </a>
        </div>
        <nav className="nav">
          <ul className={"nav-menu active"}>
            <li className="nav-item">
              <Link
                to="landing"
                spy={true}
                smooth={true}
                offset={-100}
                duration={100}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-50}
                duration={100}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="services"
                spy={true}
                smooth={true}
                offset={-50}
                duration={100}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="projects"
                spy={true}
                smooth={true}
                offset={-50}
                duration={100}
              >
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-50}
                duration={100}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
