import React, { useRef } from "react";
import "./Contact.scss";

import emailjs from "@emailjs/browser";

import Container from "@mui/material/Container";

const Contact = () => {
  const formRef = useRef();
  let loader = document.querySelector(".lds-ring");

  const sendEmail = (event) => {
    event.preventDefault();
    let form = document.querySelector(".contactForm");
    loader.style.display = "inline-block";
    form.classList.add("closeForm");
    form.style.visibility = "hidden";

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formRef.current,
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        (result) => {
          collapseForm();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const collapseForm = () => {
    let msg = document.querySelector(".submitMsg");
    loader.style.display = "none";
    msg.style.visibility = "visible";
    msg.classList.add("showMsg");
  };

  return (
    <div id="contact" className="contact">
      <div className="sectionTitle">
        <h2>Contact Me</h2>
      </div>
      <Container maxWidth="lg">
        <div className="contactContent">
          <form
            className="contactForm"
            ref={formRef}
            onSubmit={(e) => sendEmail(e)}
          >
            <div className="formInput">
              <label htmlFor="formTitle"> Title </label>
              <input
                id="formTitle"
                type="text"
                placeholder="Message Title"
                name="form_title"
                required
              />
            </div>
            <div className="formInput">
              <label htmlFor="emailInput"> Email </label>
              <input
                id="emailInput"
                type="email"
                placeholder="Insert your email"
                name="from_email"
                required
              />
            </div>
            <div className="formInput">
              <label htmlFor="messageInput"> Message </label>
              <textarea
                id="messageInput"
                rows={4}
                placeholder="Insert your message"
                name="message"
                required
              ></textarea>
            </div>
            <input type="submit" value="Send Message" />
          </form>
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="submitMsg">
            <h2>Thanks for your message</h2>
            <p>I will answer as soon as possible</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
