import React from "react";
import "./Landing.scss";

import Container from "@mui/material/Container";

import CV from "../../assets/docs/CV.pdf";

import picDesktop from "../../assets/img/picDesktop.jpg";
import picMobile from "../../assets/img/picMobile.png";

import useResponsive from "../../utils/useResponsive";

import { getAnalytics, logEvent } from "firebase/analytics";

const LandingPage = () => {
  const { md } = useResponsive();

  const handleEvent = (event, prop) => {
    const analytics = getAnalytics();
    logEvent(analytics, "click_" + prop);
  };

  return (
    <>
      <section id="landing" className="landingSection">

        <div className="landingContent">
          <h1>Luís Fernandes</h1>
          <div className="job">
            <h2>Software&nbsp;Developer</h2>
          </div>
        </div>
      </section>
      <seciton id="about">
        <Container maxWidth="lg">
          <div className="aboutSection">
            <div className="sectionTitle">
              <h2>About Me</h2>
            </div>
            <div className="aboutContent">
              <div className="rectangle"></div>
              <div className="profilePic">
                {!md ? (
                  <img src={picDesktop} alt={picDesktop} />
                ) : (
                  <img src={picMobile} alt={picMobile} />
                )}
              </div>
              <div className="aboutInfo">
                <p className="description">
                  Hi, my name is Luís Fernandes and I'm a 22 years old Software
                  Developer from Porto currently working in{" "}
                  <a
                    target="_blank"
                    href="https://www.mog-technologies.com/"
                  >
                    MOG Technologies
                  </a>
                  . I have a Degree in Technologies and Information Systems for
                  the Web from the ESMAD - Porto Polytechnic Institute. I
                  consider myself very ambitious, hardworking and I am always
                  ready to learn new skills that will improve my career as well
                  as my personal life.
                </p>
                <div className="skills">
                  <h4>Skills: </h4>
                  <label>React | Vue.js | Node.js | Git | SQL | Strapi</label>
                </div>
                <div className="awards">
                  <h4>Awards: </h4>
                  <label>
                    First Place in JuezLTI Contest of EU Code Week, 2021
                  </label>
                </div>
                <div className="aboutAnchor">
                  <a
                    onClick={(e) => handleEvent(e, "CV")}
                    className="anchor"
                    href={CV}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Curriculum Vitae
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </seciton>
    </>
  );
};

export default LandingPage;
